import { Directive,
  ElementRef,
  HostListener,
  HostBinding,
  AfterViewInit } from '@angular/core';


declare interface Position {
  offsetX: number;
  offsetY: number;
}
@Directive({
  selector: '[myCanvas]',
})
export class CanvasDirective implements AfterViewInit {
  constructor(private el: ElementRef) {
    // uso the ElementRef per accedere al canvas e settiamo tutte le sue proprieta'
    this.canvas = this.el.nativeElement;
    this.canvas.width = 800;
    this.canvas.height = 400;
    // creo canvas context.
    this.ctx = this.canvas.getContext('2d');
    this.ctx.lineJoin = 'round';
    this.ctx.lineCap = 'round';
    this.ctx.lineWidth = 2;
    this.ctx.font = "16px Arial";
    this.ctx.fillStyle = "#858585";
  }
  canvas: HTMLCanvasElement;
  ctx: CanvasRenderingContext2D;
  userStrokeStyle = '#858585';


  position: {
    start: {};
    stop: {};
  };
  //init: number ;
  // This will hold a list of positions recorded throughout the duration of a paint event
  line = [];
  // Since there's no auth setup, we'll need to able to tell users and guests
  // This object will hold the start point of any paint event.
   prevPos: Position = {
    offsetX: 0,
    offsetY: 0,
  };
  // This will be set to true when a user starts painting
  isPainting = false;
  keys: string;
  posX: number;

  @HostListener('document:keypress', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    console.log(event.key);
    if (event.keyCode !== 13 ) {
        this.keys = (String.fromCharCode(event.keyCode));
        this.ctx.fillText(this.keys, this.prevPos.offsetX, this.prevPos.offsetY);
        this.prevPos.offsetX += this.ctx.measureText(this.keys).width;
    } else {
        console.log("pigiat return" + this.prevPos.offsetX, this.prevPos.offsetY);
        this.prevPos.offsetX = this.posX;
        this.prevPos.offsetY = this.prevPos.offsetY + 16;
        this.keys = (String.fromCharCode(event.keyCode));
        this.ctx.fillText(this.keys, this.prevPos.offsetX, this.prevPos.offsetY);
      }
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown({ offsetX, offsetY }) {
    this.isPainting = true;
    // Get the offsetX and offsetY properties of the event.
    this.prevPos = {
      offsetX,
      offsetY,
    };
    
    //this.init = this.prevPos.offsetX;
    //console.log('inizio' + this.init);
  }
  @HostListener('mousemove', ['$event'])
  onMouseMove({ offsetX, offsetY }) {
    if (this.isPainting) {
      const offSetData = { offsetX, offsetY };
      // Set the start and stop position of the paint event.
      this.position = {
        start: { ...this.prevPos },
        stop: { ...offSetData },
      };
      // Add the position to the line array
      this.line = this.line.concat(this.position);
      this.draw(this.prevPos, offSetData, this.userStrokeStyle);
       this.posX = offSetData.offsetX;
    }
  }
  @HostListener('mouseup')
  onMouseUp() {
    if (this.isPainting) {
      this.isPainting = false;

    }
  }
  @HostListener('mouseleave')
  onmouseleave() {
    if (this.isPainting) {
      this.isPainting = false;

    }
  }
  @HostBinding('style.background') background = 'black';

  // The draw method takes three parameters; the prevPosition, currentPosition and the strokeStyle
  draw(
    { offsetX: x, offsetY: y }: Position,
    { offsetX, offsetY }: Position,
    strokeStyle
  ){
    // begin drawing
    this.ctx.beginPath();
    this.ctx.strokeStyle = strokeStyle;
    // Move the the prevPosition of the mouse
    this.ctx.moveTo(x, y);
    // Draw a line to the current position of the mouse
    this.ctx.lineTo(offsetX, offsetY);
    // Visualize the line using the strokeStyle
    this.ctx.stroke();
    this.prevPos = {
      offsetX,
      offsetY,
    };
  }
  
/*   download(){
        let download = document.getElementById("download") as HTMLCanvasElement;
        let image = document.getElementById("el") as HTMLCanvasElement;
        image.toDataURL("image/png");
                    
        download.setAttribute("href", image);

    }*/
  ngAfterViewInit() {}
}
//https://medium.com/@erbalvindersingh/fetch-an-image-from-url-in-angular-4-and-store-locally-4928d3b504fc
//https://developer.mozilla.org/en-US/docs/Web/HTML/CORS_enabled_image
//https://mostafazke.github.io/ng-whiteboard/
//https://github.com/mostafazke/ng-whiteboard
//https://developer.aliyun.com/mirror/npm/package/dracola-168-ng2-canvas-whiteboard
